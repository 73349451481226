import PropTypes from 'prop-types'
// @mui
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import TextMaxLine from 'src/components/text-max-line'
import { Button, IconButton, LinearProgress, Typography, Tooltip } from '@mui/material'
import { useTheme } from '@emotion/react'
import { StyledIcon } from 'src/components/snackbar/styles'
import Iconify from 'src/components/iconify'
import { cancelPrint } from 'src/reducers/print'
import { useDispatch } from 'react-redux'
import { ajaxCall } from 'src/reducers/axios'
import moment from 'moment'

// ----------------------------------------------------------------------

export default function PrintItem({ item }) {
  const theme = useTheme()

  const dispatch = useDispatch()

  const getColor = (status) => {
    switch (status) {
      case 'error':
      case 'cancelled':
        return 'error.main'
      case 'success':
        return 'success.main'
      case 'waiting':
        return 'info.main'
      case 'progress':
        return 'primary.main'
      default:
        return 'text.disabled'
    }
  }

  const getIconColor = (status) => {
    switch (status) {
      case 'error':
      case 'cancelled':
        return 'error'
      case 'success':
        return 'success'
      case 'waiting':
        return 'info'
      case 'progress':
        return 'primary'
      default:
        return 'primary'
    }
  }

  const getIcon = (status) => {
    switch (status) {
      case 'cancelled':
        return 'fluent-mdl2:circle-stop-solid'
      case 'error':
        return 'fluent-mdl2:status-error-full'
      case 'success':
        return 'iconamoon:file-check-bold'
      case 'waiting':
        return 'majesticons:clock'
      case 'progress':
        return 'line-md:downloading-loop'
      default:
        return 'line-md:downloading-loop'
    }
  }

  const handleCancelPrint = () => {
    dispatch(
      cancelPrint({
        id: item.id,
        url: item.deleteConfig.endpoint,
        method: item.deleteConfig.method,
      })
    )
  }

  const handleClick = () => {
    dispatch(
      ajaxCall({
        config: {
          url: item.getConfig.endpoint,
          method: item.getConfig.method,
          params: {},
          responseType: 'arraybuffer',
        },
        success: (res) => {
          const blob = new Blob([res.data], { type: res.headers['content-type'] })

          const url = URL.createObjectURL(blob)

          window.open(url, '_blank')

          URL.revokeObjectURL(url)
        },
        error: () => {},
        activeLoading: false,
        fullResponse: true,
      })
    )
  }

  const renderText = (
    <ListItemText
      disableTypography
      primary={
        <Tooltip title={item.title}>
          <Box>
            <TextMaxLine line={2}>{item.title}</TextMaxLine>
          </Box>
        </Tooltip>
      }
      secondary={
        <Stack direction='column' sx={{ mt: -0.2 }}>
          <Typography variant='caption' color={theme.palette.grey[500]}>
            {item.info}
          </Typography>
          <Stack
            direction='row'
            alignItems='center'
            sx={{ typography: 'caption', color: getColor(item.status), mt: 0.5 }}
            divider={
              <Box
                sx={{
                  width: 2,
                  height: 2,
                  bgcolor: 'currentColor',
                  mx: 0.5,
                  borderRadius: '50%',
                }}
              />
            }
          >
            {item.subtitle}
            {moment(item.time, 'DD/MM/YYYY HH:mm').fromNow()}
          </Stack>
        </Stack>
      }
    />
  )

  const renderButton = (
    <>
      {item.status === 'success' && (
        <Button variant='soft' size='small' sx={{ ml: 1 }} onClick={handleClick}>
          Apri
        </Button>
      )}
      <IconButton onClick={handleCancelPrint} sx={{ ml: 1 }}>
        <Iconify icon='iconamoon:close' />
      </IconButton>
    </>
  )

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: '12px',
        alignItems: 'center',
        borderBottom: (th) => `dashed 1px ${th.palette.divider}`,
      }}
    >
      {item.status === 'progress' && (
        <LinearProgress
          variant='determinate'
          value={item.progress}
          sx={{
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}

      <StyledIcon color={getIconColor(item.status)}>
        <Iconify icon={getIcon(item.status)} width={24} />
      </StyledIcon>

      <Stack sx={{ flexGrow: 1 }}>{renderText}</Stack>

      {renderButton}
    </ListItemButton>
  )
}

PrintItem.propTypes = {
  item: PropTypes.object,
}

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      line={1}
      sx={{
        lineHeight: '1.2rem',
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
      }}
    />
  )
}
