// ----------------------------------------------------------------------

export function cssBaseline(theme) {
  const lightMode = theme.palette.mode === 'light'

  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          overflow: 'hidden'
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto !important'
        },
        '#root, #__next': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          maxWidth: '100%',
          display: 'inline-block',
          verticalAlign: 'bottom',
        },
        '::-webkit-scrollbar': {
          backgroundColor: 'inherit',
          width: '8px'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: lightMode ? theme.palette.grey[400] : theme.palette.grey[700],
          borderRadius: theme.shape.borderRadius,
        }
      },
    },
  }
}
