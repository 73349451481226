import PropTypes from 'prop-types'
import { useEffect, useCallback, useState } from 'react'
// routes
import { paths } from 'src/routes/paths'
import { useRouter } from 'src/routes/hooks'
//
import { useAuthContext } from '../hooks'

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter()

  const { authenticated } = useAuthContext()

  const [checked, setChecked] = useState(false)

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString()

      const loginPath = paths.login

      const href = `${loginPath}?${searchParams}`

      router.replace(href)
    } else {
      setChecked(true)
    }
  }, [authenticated, router])

  useEffect(() => {
    check()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!checked) {
    return null
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node,
}
