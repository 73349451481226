import PropTypes from 'prop-types'
// @mui
import Stack from '@mui/material/Stack'
// components
import Logo from 'src/components/logo'
import { NavLink } from 'react-router-dom'
import { Button } from '@mui/material'

export default function RecoveryLayout({ children }) {
  return (
    <Stack>
      <Stack
        direction='row'
        sx={{
          justifyContent: 'space-between',
          py: 2,
          px: 3,
        }}
      >
        <Logo
          sx={{
            width: 40,
            height: 40,
          }}
        />
        <Button component={NavLink} to='/help'>
          Hai bisogno di aiuto?
        </Button>
      </Stack>

      <Stack
        sx={{
          width: 1,
          mx: 'auto',
          maxWidth: 500,
          alignItems: 'center',
          justifyContent: 'center',
          px: 5,
          py: 4,
        }}
      >
        {children}
      </Stack>
    </Stack>
  )
}

RecoveryLayout.propTypes = {
  children: PropTypes.node,
}
