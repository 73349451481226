import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {
  openMessage: false,
  messageConfig: {},
  timeout: null,
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    closeMessage: (state) => {
      clearTimeout(state.timeout)

      state.openMessage = false
    },
  },
  extraReducers(builder) {
    builder.addCase(showMessage.fulfilled, (state, action) => {
      state.openMessage = true

      state.messageConfig = action.payload

      state.timeout = action.payload.timeout
    })
  },
})

export const showMessage = createAsyncThunk('messagge/showMessage', async (data, thunkAPI) => {
  const state = thunkAPI.getState().message

  clearTimeout(state.timeout)

  const result = { ...data }

  if (data.autoHide) {
    result.timeout = setTimeout(() => {
      thunkAPI.dispatch(closeMessage())
    }, 5000)
  }

  return result
})

export const { closeMessage } = messageSlice.actions

export default messageSlice.reducer
