import { useLocation, matchPath } from 'react-router-dom'

// ----------------------------------------------------------------------

export function useActiveLink(path, children = []) {
  const { pathname } = useLocation()

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false

  let childActive = false

  children.forEach(child => {
    if (child.path && matchPath({ path: child.path, end: false }, pathname)) {
      childActive = true
    }
  })

  return childActive || deepActive
}
