// i18n
import 'src/locales/i18n'

// scrollbar
import 'simplebar-react/dist/simplebar.min.css'

// lightbox
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'

// map
import 'mapbox-gl/dist/mapbox-gl.css'

// editor
import 'react-quill/dist/quill.snow.css'

// carousel
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

// image
import 'react-lazy-load-image-component/src/effects/blur.css'

// config
import './Config'

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections'
// theme
import ThemeProvider from 'src/theme'
// locales
import { LocalizationProvider } from 'src/locales'
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top'
// components
import { MotionLazy } from 'src/components/animate/motion-lazy'
import ProgressBar from 'src/components/progress-bar'
import { SettingsDrawer, SettingsProvider } from 'src/components/settings'
import SnackbarProvider from 'src/components/snackbar/snackbar-provider'
// auth
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AuthConsumer, AuthProvider } from 'src/auth/context/jwt'
import { Snackbar } from './components/snackbar'
import { updateBadge } from './reducers/sidebar'
import { socket } from './socket'
import { addNotifications, setSocketStatus } from './reducers/notification'
import { updatePrint } from './reducers/print'

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch()

  useScrollToTop()

  useEffect(() => {
    const onConnect = () => {
      dispatch(setSocketStatus(false))
    }

    const onDisconnect = () => {
      dispatch(setSocketStatus(true))
    }

    const onConnectError = () => {
      socket.connect()
    }

    const onNotificationEvent = (newNotifications) => {
      dispatch(addNotifications(newNotifications))
    }

    const onPrintEvent = (print) => {
      dispatch(updatePrint(print))
    }

    const onSidebarEvent = (config) => {
      dispatch(updateBadge(config))
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('sidebar', onSidebarEvent)
    socket.on('notification', onNotificationEvent)
    socket.on('print', onPrintEvent)
    socket.on('connect_error', onConnectError)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
      socket.off('sidebar', onSidebarEvent)
      socket.off('notification', onNotificationEvent)
      socket.off('print', onPrintEvent)
      socket.off('connect_error', onConnectError)
    }
  }, [dispatch])

  return (
    <LocalizationProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: true, // false = applica maxWidth
        }}
      >
        <AuthProvider>
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <Snackbar />
                <AuthConsumer>
                  <Router />
                </AuthConsumer>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </AuthProvider>
      </SettingsProvider>
    </LocalizationProvider>
  )
}
