import { memo } from 'react'
import { useSelector } from 'react-redux'
// @mui
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
// theme
import { bgBlur } from 'src/theme/css'
// components
import { NavSectionHorizontal } from 'src/components/nav-section'
//
import { HEADER } from '../config-layout'
import { HeaderShadow } from '../_common'

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme()

  const navData = useSelector((state) => state.app.sidebar)

  return (
    <AppBar
      component='nav'
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal
          data={navData}
        />
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  )
}

export default memo(NavHorizontal)
