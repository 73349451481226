import merge from 'lodash/merge'
import {
  it as itITAdapter,
} from 'date-fns/locale'
// core
import {
  itIT as itITCore,
} from '@mui/material/locale'
// date-pickers
import {
  itIT as itITDate,
} from '@mui/x-date-pickers/locales'
// data-grid
import {
  itIT as itITDataGrid,
} from '@mui/x-data-grid/locales'

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Italiano',
    value: 'it',
    systemValue: merge(itITDate, itITDataGrid, itITCore),
    adapterLocale: itITAdapter,
    icon: 'flagpack:it',
  }
]

export const defaultLang = allLangs[0] // IT

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
