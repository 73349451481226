import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'

export default function Snackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { openMessage, messageConfig } = useSelector((state) => state.message)

  useEffect(() => {
    if (openMessage && messageConfig && messageConfig.message) {
      enqueueSnackbar(messageConfig.message, {
        variant: messageConfig.type,
        tooltip: messageConfig.tooltip || '',
        config: messageConfig,
        autoHideDuration: messageConfig.autoHide ? 5000 : null,
        anchorOrigin: {
          vertical: messageConfig.positionUp ? 'top' : 'bottom',
          horizontal: 'right'
        }
      })
    }
  }, [openMessage, messageConfig, enqueueSnackbar, closeSnackbar])

  return ''
}
