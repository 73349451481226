import agendaReducer from './agenda'
import appReducer from './app'
import axiosReducer from './axios'
import confirmReducer from './confirm'
import formReducer from './form'
import messageReducer from './message'
import printReducer from './print'
import notificationReducer from './notification'
import sidebarReducer from './sidebar'
import surveyReducer from './survey'

const reducers = {
  agenda: agendaReducer,
  app: appReducer,
  axios: axiosReducer,
  confirm: confirmReducer,
  form: formReducer,
  message: messageReducer,
  notification: notificationReducer,
  print: printReducer,
  sidebar: sidebarReducer,
  survey: surveyReducer
}

export default reducers
