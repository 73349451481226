import axios from 'axios'

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject(error.response)
)

export default axiosInstance
