// @mui
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useDispatch, useSelector } from 'react-redux'
import { closeNotification, markAsRead } from 'src/reducers/notification'
import Iconify from 'src/components/iconify'
import { StyledIcon } from 'src/components/snackbar/styles'
import { ajaxCall } from 'src/reducers/axios'
import { NavLink } from 'react-router-dom'

// ----------------------------------------------------------------------

export default function NotificationDialog() {
  const { isOpen, notificationConfig } = useSelector((state) => state.notification)

  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(closeNotification())

    if (notificationConfig.apiCall) {
      dispatch(
        ajaxCall({
          config: {
            url: notificationConfig.apiCall.endpoint,
            method: notificationConfig.apiCall.method || 'get',
            params: {
              id: notificationConfig.id
            }
          },
          success: () => {
            dispatch(markAsRead(notificationConfig.id))
          },
          error: () => {}, // TODO gestire errore
          activeLoading: false,
        })
      )
    }
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={isOpen}>

      <DialogTitle sx={{ pb: 2, display: 'flex', alignItems: 'center' }}>
        <StyledIcon color={notificationConfig.color || 'warning'}>
          <Iconify icon={notificationConfig.icon || 'eva:alert-triangle-fill'} width={24} />
        </StyledIcon>
        {notificationConfig.title}
      </DialogTitle>

      {notificationConfig.text && (
        <DialogContent sx={{ typography: 'body2' }}> {notificationConfig.text} </DialogContent>
      )}

      <DialogActions>
        {notificationConfig.path && <Button
          variant='soft'
          color='primary'
          component={NavLink}
          onClick={onClose}
          sx={{ mr: 1 }}
          to={window.appConfig.appPrefix + notificationConfig.path}
        >
          Vai alla pagina
        </Button>}
        <Button variant='outlined' color='inherit' onClick={onClose}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  )
}
