import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getToken, onMessage } from 'firebase/messaging'
// @mui
import Box from '@mui/material/Box'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'
// components
import { useSettingsContext } from 'src/components/settings'
import { Confirm } from 'src/components/custom-dialog'
import { ajaxCall } from 'src/reducers/axios'
//
import { showMessage } from 'src/reducers/message'
import { addNotifications, getNotifications, openNotification, resetNotifications, setPushNotificationStatus } from 'src/reducers/notification'
import { getPrintsStack } from 'src/reducers/print'
import Main from './main'
import Header from './header'
import NavMini from './nav-mini'
import NavVertical from './nav-vertical'
import NavHorizontal from './nav-horizontal'
import { getSidebar } from '../../reducers/app'
import Footer from './footer'
import { messaging } from '../../utils/firebase/firebase-config'
import NotificationDialog from './notification'

// ----------------------------------------------------------------------

const requestPushNotificationPermission = (dispatch) => (
  new Promise((resolve, reject) => {
    Notification.requestPermission().then(permission => {
      switch (permission) {
        case 'granted':
          return getToken(messaging, {
            vapidKey: window.appConfig.push_notification.vapidPubKey,
          }).then(token => {
            dispatch(
              ajaxCall({
                config: {
                  url: window.appConfig.actions.notification.subscribe.path,
                  method: 'POST',
                  data: {
                    token,
                    endpoint: 'http://localhost:3005' // TODO sostituire (e capire se serve)
                  }
                },
                success: () => {
                  resolve(true)
                },
                error: () => {
                  reject()
                },
              })
            )
          })
        case 'denied':
          resolve(false)

          break
        default:
          break
      }

      resolve(false)
    }).catch(reject)
  })
)

export default function MainLayout({ children }) {
  const settings = useSettingsContext()

  const lgUp = useResponsive('up', 'lg')

  const nav = useBoolean()

  const isHorizontal = settings.themeLayout === 'horizontal'

  const isMini = settings.themeLayout === 'mini'

  const renderNavMini = <NavMini />

  const renderHorizontal = <NavHorizontal />

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />

  const dispatch = useDispatch()

  const lastNotifications = useSelector((state) => state.notification.lastNotifications)

  useEffect(() => {
    if (lastNotifications.length) {
      dispatch(resetNotifications())

      const hasModal = lastNotifications.find(n => n.modal)

      if (hasModal) {
        dispatch(
          openNotification(hasModal)
        )
      } else {
        lastNotifications.forEach(notif => {
          setTimeout(() => {
            dispatch(
              showMessage({
                message: notif.title,
                type: 'notification',
                autoHide: true,
                positionUp: true,
                color: notif.color,
                icon: notif.icon
              })
            )
          }, 0)
        })
      }

    }
  }, [dispatch, lastNotifications])

  useEffect(() => {
    dispatch(getSidebar())

    dispatch(getNotifications())

    dispatch(getPrintsStack())

    requestPushNotificationPermission(dispatch).then(ok => {
      if (ok) {
        dispatch(setPushNotificationStatus(false))
      } else {
        dispatch(setPushNotificationStatus(true))
      }
    }).catch(err => {
      dispatch(setPushNotificationStatus(true))
    })
  }, [dispatch])

  const onNotificationEvent = useCallback(
    (newNotifications) => {
      dispatch(addNotifications(newNotifications))
    },
    [dispatch]
  )

  onMessage(messaging, (payload) => {
    try {
      const body = JSON.parse(payload.notification?.body)

      onNotificationEvent([body])
    } catch (error) {
      console.error(error)
    }
  })

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>

        <Footer />
      </>
    )
  }

  if (isMini) {
    return (
      <>
        <Confirm />

        <NotificationDialog />

        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 'calc(100% - 35px)',
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>

        <Footer />
      </>
    )
  }

  return (
    <>
      <Confirm />

      <NotificationDialog />

      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 'calc(100% - 35px)',
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>

      <Footer />
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
}
