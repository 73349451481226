import PropTypes from 'prop-types'
import { forwardRef } from 'react'
// @mui
import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
// routes
import { RouterLink } from 'src/routes/components'
import { paths } from 'src/routes/paths'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme()

  const PRIMARY_LIGHT = theme.palette.primary.light

  const PRIMARY_MAIN = theme.palette.primary.main

  const PRIMARY_DARK = theme.palette.primary.dark

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component='div'
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        backgroundSize: 'cover',
        ...sx
      }}
      {...other}
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='2000 100 1 3500'>
        <defs>
          <linearGradient id='BG1' x1='100%' x2='50%' y1='9.946%' y2='50%'>
            <stop offset='0%' stopColor={PRIMARY_DARK} />
            <stop offset='100%' stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id='BG2' x1='50%' x2='50%' y1='0%' y2='100%'>
            <stop offset='0%' stopColor={PRIMARY_LIGHT} />
            <stop offset='100%' stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule='evenodd' stroke='none' strokeWidth='1'>
          <path
            fill='url(#BG1)'
            d='m764 3029.1c0 0 84.3 213.7 486.2 247.6 0 0-117.4-101.6 0-239.4 117.4-137.7 280-126.4 280-126.4 0 0-280-250.6-422.3-264.2-142.2-13.5-268.7 97.1-298 142.3-29.4 45.1-72.2 196.7-45.9 240.1z'
          />
          <path
            fill='url(#BG1)'
            d='m764 3029c-26.3-43.3 16.5-194.9 45.9-240 17.4-26.8 69-76.4 137.7-109.7 126.7 8.5 316.2 44.4 345.9 50.2q1.1 0.6 2.1 1.3c-21.5 6.5-228.1 73.2-264.4 263.6-38.4 201 219 282.3 219 282.3-401.9-33.9-486.2-247.7-486.2-247.7z'
          />
          <path
            fill='url(#BG2)'
            d='m2142.8 3336.2c0 0-316.1-243.8-593-379.3-277-135.5-511.8-180.6-620.2-141.5-108.4 39.1-159.5 114.4-165.6 213.7 0 0-73.4-118.5 59.7-384.4 133-265.8 277.5-470.5 434.1-470.5 156.5 0 833.9 87.3 1131.9 514.7 298 427.5 177.6 523.9 96.3 575.1-81.2 51.1-234.8 123.4-343.2 72.2z'
          />
          <path
            fill='url(#BG2)'
            d='m1336 2484.1c527.8 98.3 1153.1 615.3 1235 684.6-16.8 49.2-53.7 75.6-85 95.3-81.2 51.1-234.8 123.4-343.2 72.2 0 0-316.1-243.8-593-379.3-277-135.5-511.8-180.6-620.2-141.5-108.4 39.1-159.5 114.4-165.6 213.7 0 0-73.4-118.5 59.7-384.4 28.6-57.1 57.7-111.1 87.4-161.2 108.4-26.1 247.5-32.4 424.9 0.6z'
          />
          <path
            fill='url(#BG1)'
            d='m1318.8 1787l419.5-726.5c108.5-188.1 380-188.1 488.8 0 0 0 407.8 706.7 414.3 718.1 212.8 368.4 594.3 1133 467.8 1385.9-82.6 165.2-386.1 206-586.5 214.4-106.6 4.7-184.3 0-184.3 0-153.6-11.4-195.6-42.7-195.6-42.7 358.2 14.9 310.1-406.4 57.2-864.2-252.9-457.5-881.2-685-881.2-685z'
          />
          <path
            fill='url(#BG1)'
            d='m2200 2472c-252.9-457.5-881.2-685-881.2-685l283.2-490.5c104.6 53.1 233.9 127.6 381.2 230.3 397.4 277 855 1132 806.9 1499.2-48.2 367.3-647.3 310.2-647.3 310.2 358.2 14.9 310.1-406.4 57.2-864.2z'
          />
        </g>
      </svg>
    </Box>
  )

  if (disabledLink) {
    return logo
  }

  return (
    <Link component={RouterLink} href={paths.homepage} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
}

export default Logo