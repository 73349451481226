import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// components
import { SplashScreen } from 'src/components/loading-screen'
// auth
import { AuthGuard, GuestGuard } from 'src/auth/guard'
// layouts
import MainLayout from 'src/layouts/main'
import LoginLayout from 'src/layouts/login/login'
import RecoveryLayout from 'src/layouts/login/recovery'
import { basicPaths } from '../basic-paths'

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('src/pages/500'))
const Page403 = lazy(() => import('src/pages/403'))
const Page404 = lazy(() => import('src/pages/404'))

const DealerView = lazy(() => import('src/sections/dealer'))
const Login = lazy(() => import('src/pages/auth/login'))
const Recovery = lazy(() => import('src/pages/auth/recovery'))
const NewPassword = lazy(() => import('src/pages/auth/new-password'))
const PasswordChanged = lazy(() => import('src/pages/auth/password-changed'))

// ----------------------------------------------------------------------

const routes = basicPaths
  .map((route) =>
    route.paths.map((path) => ({
      path,
      ...(route.options || {}),
      element: <DealerView pageUrl={route.pageUrl} />
    }))
  )
  .flat()

export const mainRoutes = [
  {
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: routes
  },
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> }
    ]
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <LoginLayout>
          <Login />
        </LoginLayout>
      </GuestGuard>
    )
  },
  {
    path: 'recovery',
    element: (
      <GuestGuard>
        <RecoveryLayout>
          <Recovery />
        </RecoveryLayout>
      </GuestGuard>
    )
  },
  {
    path: 'new-password',
    element: (
      <GuestGuard>
        <RecoveryLayout>
          <NewPassword />
        </RecoveryLayout>
      </GuestGuard>
    )
  },
  {
    path: 'password-changed',
    element: (
      <GuestGuard>
        <RecoveryLayout>
          <PasswordChanged />
        </RecoveryLayout>
      </GuestGuard>
    )
  }
]
