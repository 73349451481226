import { Navigate, useRoutes } from 'react-router-dom'
//
import { lazy } from 'react'
import { mainRoutes } from './main'
import { paths } from '../paths'

const Page404 = lazy(() => import('src/pages/404'))

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Page404 /> },
  ])
}
