import { m } from 'framer-motion'
import { useState, useCallback, useEffect } from 'react'
// @mui
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Badge from '@mui/material/Badge'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'
// components
import Label from 'src/components/label'
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar'
import { varHover } from 'src/components/animate'
//
import { useDispatch, useSelector } from 'react-redux'
import { ajaxCall } from 'src/reducers/axios'
import { markAsRead, toggleNotificationDrawer } from 'src/reducers/notification'
import { Box } from '@mui/material'
import { useTheme } from '@emotion/react'
import NotificationItem from './notification-item'

export default function NotificationsPopover() {
  const drawer = useBoolean()

  const theme = useTheme()

  const smUp = useResponsive('up', 'sm')

  const {
    notifications,
    openNotificationDrawer,
    pushNotificationDisabled,
    socketDisconnected,
    serviceDown
  } = useSelector((state) => state.notification)

  const [currentTab, setCurrentTab] = useState('all')
  const [toShow, setToShow] = useState(notifications)

  const dispatch = useDispatch()

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length

  const TABS = [
    {
      value: 'all',
      label: 'Tutte',
      count: notifications.length
    },
    {
      value: 'unread',
      label: 'Non lette',
      count: totalUnRead
    }
  ]

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
  }, [])

  useEffect(() => {
    setToShow(currentTab === 'all' ? notifications : notifications.filter((el) => el.isUnRead))
  }, [notifications, currentTab])

  useEffect(() => {
    if (openNotificationDrawer) {
      dispatch(toggleNotificationDrawer())

      drawer.onTrue()
    }
  }, [openNotificationDrawer, drawer, dispatch])

  const handleMarkAllAsRead = () => {
    const callsMap = {}

    notifications.forEach((n) => {
      if (n.apiCall) {
        callsMap[n.apiCall.endpoint] = callsMap[n.apiCall.endpoint] || {}

        callsMap[n.apiCall.endpoint][n.apiCall.method] =
          callsMap[n.apiCall.endpoint][n.apiCall.method] || []

        callsMap[n.apiCall.endpoint][n.apiCall.method].push(n)
      }
    })

    Object.entries(callsMap).forEach(([endpoint, methods]) => {
      Object.entries(methods).forEach(([method, arr]) => {
        const ids = arr.map((n) => n.id)

        dispatch(
          ajaxCall({
            config: {
              url: endpoint,
              method: method || 'get',
              params: {
                id: ids
              }
            },
            success: () => {
              ids.forEach((id) => {
                dispatch(markAsRead(id))
              })
            },
            error: () => {}, // TODO gestire errore
            activeLoading: false
          })
        )
      })
    })
  }

  const handleMarkAsRead = (notification) => {
    if (notification.apiCall) {
      dispatch(
        ajaxCall({
          config: {
            url: notification.apiCall.endpoint,
            method: notification.apiCall.method || 'get',
            params: {
              id: notification.id
            }
          },
          success: () => {
            dispatch(markAsRead(notification.id))
          },
          error: () => {},
          activeLoading: false
        })
      )
    }
  }

  const renderHead = (
    <Stack direction='row' alignItems='center' sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant='h6' sx={{ flexGrow: 1 }}>
        Notifiche
      </Typography>

      {!!totalUnRead && (
        <Tooltip title='Segna tutte come già lette'>
          <IconButton color='primary' onClick={handleMarkAllAsRead}>
            <Iconify icon='eva:done-all-fill' />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon='mingcute:close-line' />
        </IconButton>
      )}
    </Stack>
  )

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition='end'
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={ (tab.value === 'unread' && 'info') || 'default' }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': { mr: 3 }
          }}
        />
      ))}
    </Tabs>
  )

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {toShow.map((notification, i) => (
          <NotificationItem
            key={i}
            notification={notification}
            markAsRead={handleMarkAsRead}
            closeDrawer={drawer.onFalse}
          />
        ))}
      </List>
    </Scrollbar>
  )

  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={(socketDisconnected || serviceDown) ? '!' : totalUnRead} color='error'>
          <Iconify icon='solar:bell-bing-bold-duotone' width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor='right'
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        {(socketDisconnected || serviceDown) && <Box  sx={{
          p: 2,
          bgcolor: theme.palette.error.light
        }}>
          <Typography variant='subtitle2' display='flex' alignItems='center' sx={{ lineHeight: 1.2 }}>
            <Iconify sx={{ mr: 1.5, minWidth: 30 }} width={30} icon='ooui:error' color={theme.palette.error.dark} />
            Attenzione: per un problema interno, il servizio delle notifiche al momento non è attivo, riprova più tardi effettuando un refresh della pagina.
          </Typography>
        </Box>}

        <Divider />

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
        </Stack>

        <Divider />

        {renderList}

        <Divider />

        {pushNotificationDisabled && <Box  sx={{ p: 2 }}>
          <Typography variant='body2' display='flex' alignItems='center'>
            <Iconify sx={{ mr: 1 }} icon='fluent:warning-24-filled' color={theme.palette.warning.main} />
            Non hai abilitato le notifiche push
          </Typography>
        </Box>}
      </Drawer>
    </>
  )
}
