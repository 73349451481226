import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep, isEmpty } from 'lodash'
import { ajaxCall } from './axios'

const initialState = {
  agenda: {},
  agendaSearch: null,
  selectedEvent: null,
}

export const agendaSlice = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    updateAgenda: (state, action) => {
      state.agenda = action.payload
    },
    updateAgendaSearch: (state, action) => {
      state.agendaSearch = action.payload
    },
    updateSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload
    },
  },
})

export const { updateAgenda, updateAgendaSearch, updateSelectedEvent } = agendaSlice.actions

export const loadAgenda = createAsyncThunk('agenda/load', async (obj, thunkAPI) => {
  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: obj.url,
        method: 'get',
        params: obj.params
      },
      success: (res) => {
        if (isEmpty(res) || res.current) {
          thunkAPI.dispatch(updateAgenda(res))
        }

        const eventData = obj.updateEvent

        if (eventData) {
          thunkAPI.dispatch(updateSelectedEvent(eventData))
        }
      },
      error: () => {},
      activeLoading: false,
    })
  )
})

export const searchAgenda = createAsyncThunk('agenda/search', async (obj, thunkAPI) => {
  const { agendaSearch } = thunkAPI.getState().agenda

  thunkAPI.dispatch(updateAgendaSearch(null))

  if (obj.params.preventNewSearch) {
    return
  }

  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: obj.url,
        method: obj.method,
        params: obj.params
      },
      success: (res) => {
        const { previous, clear } = obj.params

        const currentEvents = previous ? 'pastEvents' : 'futureEvents'

        const otherEvents = previous ? 'futureEvents' : 'pastEvents'

        const oldResults = clear ? {
          futureEvents: [],
          pastEvents: [],
          hasMore: {
            futureEvents: true,
            pastEvents: true,
          },
        } : cloneDeep(agendaSearch)

        const newData = res

        newData[currentEvents] = newData.events

        const firstArray = previous ? newData[currentEvents] : oldResults[currentEvents]

        let secondArray = previous ? oldResults[currentEvents] : newData[currentEvents]

        const last = firstArray[firstArray.length - 1]

        // prima di concatenare i vecchi risultati ai nuovi elimino eventuali duplicati
        let duplicateIndex = -1

        secondArray.every((element, index) => {
          if (last && element.id === last.id) {
            duplicateIndex = index

            return false
          }

          return true
        })

        secondArray = secondArray.slice(duplicateIndex + 1)

        newData[currentEvents] = firstArray.concat(secondArray)

        newData[otherEvents] = oldResults[otherEvents]

        newData.hasMore = oldResults.hasMore

        newData.hasMore[currentEvents] = res.events.length === 100

        newData.from = newData.from || oldResults.from

        newData.to = newData.to || oldResults.to

        thunkAPI.dispatch(updateAgendaSearch(newData))
      },
      error: () => {},
      activeLoading: false,
    })
  )
})

export default agendaSlice.reducer
