import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
// @mui
import Box from '@mui/material/Box'
import { Container, alpha, useTheme } from '@mui/material'
// hooks
import { useResponsive } from 'src/hooks/use-responsive'
// components
import { useSettingsContext } from 'src/components/settings'
import Iconify from 'src/components/iconify/iconify'
//
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs'
import { SplashScreen } from 'src/components/loading-screen'
import { paths } from 'src/routes/paths'
import { HEADER, NAV } from '../config-layout'

// ----------------------------------------------------------------------

const SPACING = 8

export default function Main({ children, sx, ...other }) {
  const settings = useSettingsContext()

  const lgUp = useResponsive('up', 'lg')

  const isNavHorizontal = settings.themeLayout === 'horizontal'

  const isNavMini = settings.themeLayout === 'mini'

  const titleBlock = useSelector((state) => state.app.titleBlock)

  const openLoading = useSelector((state) => state.axios.openLoading)

  const theme = useTheme()

  if (isNavHorizontal) {
    return (
      <Box
        component='main'
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {children}
      </Box>
    )
  }

  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {openLoading && <SplashScreen sx={{ position: 'fixed', bgcolor: alpha(theme.palette.background.default, 0.8) }} />}
      <Container maxWidth={false} sx={{ mb: 3 }}>
        <CustomBreadcrumbs
          sx={{ width: 1 }}
          heading={titleBlock.title}
          links={
            titleBlock.breadcrumbs
              ? [
                  {
                    path: paths.homepage,
                    icon: <Iconify icon='mdi:home' sx={{ mr: -1 }} />,
                  },
                  ...titleBlock.breadcrumbs,
                ]
              : []
          }
        />
      </Container>

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        {children}
      </Container>
    </Box>
  )
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
}
