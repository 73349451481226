import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ajaxCall } from './axios'
import { showMessage } from './message'
import { printPost } from './print'

const initialState = {
  goTo: null,
  newId: null,
  fieldPathMapping: {}
}

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    surveySaved: (state, action) => {
      state.goTo = action.payload.goTo || action.payload.data.goTo

      state.newId = action.payload.data.id
    },
    resetSurveyGoTo: (state) => {
      state.goTo = null
    },
    resetSurveyId: (state) => {
      state.newId = null
    },
    updateMapping: (state, action) => {
      state.fieldPathMapping = {
        ...state.fieldPathMapping,
        [action.payload.key]: action.payload.value
      }
    },
  },
})

export const { surveySaved, resetSurveyGoTo, resetSurveyId, updateMapping } = surveySlice.actions

export const saveSurvey = createAsyncThunk('survey/save', async (data, thunkAPI) => {
  const { params, method, payload, printParams, url } = data

  const formContent = payload

  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url,
        method: method || 'post',
        data: {
          fields: formContent
        },
        params,
      },
      success: (res) => {
        thunkAPI.dispatch(surveySaved({ data: res, ...params }))

        if (res.message) {
          setTimeout(() => {
            thunkAPI.dispatch(showMessage({
              message: res.message,
              type: res.status === 200 ? 'success' : 'error',
              autoHide: true,
            }))
          }, 200)
        }

        if (params.fromPrint) {
          thunkAPI.dispatch(printPost({
            url: printParams.endpoint,
            params: printParams
          }))
        }
      },
      error: () => {},
      activeLoading: false,
    })
  )
})

export default surveySlice.reducer
