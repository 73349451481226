import cloneDeep from 'lodash/cloneDeep'
import PropTypes from 'prop-types'
import { memo, useCallback, useEffect, useState } from 'react'
// @mui
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
//
import { useDispatch, useSelector } from 'react-redux'
import Iconify from 'src/components/iconify'
import Label from 'src/components/label'
import { ajaxCall } from 'src/reducers/axios'
import { navVerticalConfig } from '../config'
import { StyledSubheader } from './styles'

import NavList from './nav-list'

// ----------------------------------------------------------------------

function NavSectionVertical({ data, config, sx, ...other }) {
  const { badges } = useSelector((store) => store.sidebar)

  const [dataToRender, setDataToRender] = useState([])

  const dispatch = useDispatch()

  const getSum = useCallback(
    (item) => {
      let sum = badges[item.path] || 0

      if (item.children) {
        item.children.forEach((c) => {
          sum += getSum(c)
        })
      }

      return sum
    },
    [badges]
  )

  const setIcons = useCallback(
    (item) => {
      if (item.icon) {
        Object.assign(item, {
          icon: <Iconify icon={`${item.iconFamily || 'solar'}:${item.icon}`} />,
        })
      }

      const sum = getSum(item)

      if (sum) {
        Object.assign(item, {
          info: <Label color='secondary'>{sum}</Label>,
        })
      }

      if (item.children) {
        item.children.forEach((child) => {
          setIcons(child)
        })
      }

      if (item.updateBadge) {
        dispatch(
          ajaxCall({
            config: {
              url: item.updateBadge.endpoint,
              method: item.updateBadge.method,
            },
            success: () => {},
            error: () => {},
          })
        )
      }
    },
    [getSum, dispatch]
  )

  useEffect(() => {
    setDataToRender(
      cloneDeep(data).map((section) => {
        section.items.forEach((item) => {
          setIcons(item)
        })

        return section
      })
    )
  }, [data, setIcons])

  return (
    <Stack sx={{...sx, pb: 5}} {...other}>
      {dataToRender.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
          config={navVerticalConfig(config)}
        />
      ))}
    </Stack>
  )
}

NavSectionVertical.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
}

export default memo(NavSectionVertical)

// ----------------------------------------------------------------------

function Group({ subheader, items, config }) {
  const [open, setOpen] = useState(true)

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  const renderContent = items.map((list) => (
    <NavList
      key={list.title + list.path}
      data={list}
      depth={1}
      hasChild={!!list.children}
      config={config}
    />
  ))

  return (
    <List disablePadding sx={{ px: 2 }}>
      {subheader ? (
        <>
          <StyledSubheader disableGutters disableSticky onClick={handleToggle} config={config}>
            {subheader}
          </StyledSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </List>
  )
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
  subheader: PropTypes.string,
}
