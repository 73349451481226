import { m } from 'framer-motion'
// @mui
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// hooks
import { useBoolean } from 'src/hooks/use-boolean'
import { useResponsive } from 'src/hooks/use-responsive'
// components
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar'
import { varHover } from 'src/components/animate'
//
import { useSelector } from 'react-redux'
import { Box, CircularProgress } from '@mui/material'
import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'
import PrintItem from './print-item'

export default function PrintPopover() {
  const drawer = useBoolean()

  const theme = useTheme()

  const smUp = useResponsive('up', 'sm')

  const { printsStack } = useSelector((state) => state.print)

  const { socketDisconnected } = useSelector((state) => state.notification)

  const [totalProgress, setTotalProgress] = useState(0)

  const isPrinting = printsStack.length > 0

  useEffect(() => {
    const allProgress = []

    printsStack.forEach(p => {
      if (p.status !== 'error') {
        allProgress.push(p.progress || 0)
      }
    })

    const average = allProgress.reduce((a, b) => a + b, 0) / allProgress.length

    setTotalProgress(average)
  }, [printsStack])

  const renderHead = (
    <Stack direction='row' alignItems='center' sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant='h6' sx={{ flexGrow: 1 }}>
        Stampe
      </Typography>

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon='mingcute:close-line' />
        </IconButton>
      )}
    </Stack>
  )

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {printsStack.map((item, i) => (
          <PrintItem key={i} item={item} />
        ))}
      </List>
    </Scrollbar>
  )

  return (
    <>
      <Box sx={{ m: 1, position: 'relative' }}>
        <IconButton
          component={m.button}
          whileTap='tap'
          whileHover='hover'
          variants={varHover(1.05)}
          color={drawer.value ? 'primary' : 'default'}
          onClick={drawer.onTrue}
        >
          <Iconify icon='solar:printer-2-bold-duotone' width={isPrinting ? 20 : 24} />
        </IconButton>

        {isPrinting && <CircularProgress
          size={44}
          thickness={2}
          variant='determinate'
          value={totalProgress}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: -4,
            left: -4,
            zIndex: -1,
          }}
        />}

      </Box>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor='right'
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        {socketDisconnected && <Box sx={{
          p: 2,
          bgcolor: theme.palette.error.light
        }}>
          <Typography variant='subtitle2' display='flex' alignItems='center' sx={{ lineHeight: 1.2 }}>
            <Iconify sx={{ mr: 1.5, minWidth: 30 }} width={30} icon='ooui:error' color={theme.palette.error.dark} />
            Attenzione: si è verificato un problema, riprova più tardi
          </Typography>
        </Box>}

        <Divider />

        {isPrinting ? renderList : (
          <Typography textAlign='center' variant='subtitle2' sx={{ p: '1rem 2rem' }}>
            Al momento non ci sono stampe in corso, quando stamperai un documento lo troverai qui
          </Typography>
        )}
      </Drawer>
    </>
  )
}
