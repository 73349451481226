import PropTypes from 'prop-types'
import { useEffect, useReducer, useCallback, useMemo } from 'react'
//
import { useDispatch, useSelector } from 'react-redux'
import { ajaxCall } from 'src/reducers/axios'
import { setLoginFail } from 'src/reducers/form'
import { useSettingsContext } from 'src/components/settings'
import { primaryPresets } from 'src/theme/options/presets'
import { socket } from 'src/socket'
import { AuthContext } from './auth-context'
import { forceLogout, isValidToken, setSession } from './utils'

const initialState = {
  data: null,
  loading: true,
  reInit: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL': {
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    }

    case 'LOGIN': {
      return {
        ...state,
        data: action.payload,
        reInit: true
      }
    }

    case 'REGISTER': {
      return {
        ...state,
        data: action.payload,
      }
    }

    case 'LOGOUT': {
      return {
        ...state,
        reInit: false,
        data: null,
      }
    }

    case '2FA': {
      return {
        ...state,
        data: action.payload,
      }
    }

    default:
      return state
  }
}

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { appConfig } = useSelector(store => store.app)
  const { reInit } = useSelector(store => store.axios)

  const outerDispatch = useDispatch()

  const settings = useSettingsContext()

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(window.appConfig.tokenStorageKey)

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken)

        outerDispatch(ajaxCall({
          config: {
            url: appConfig.actions.auth.appConfig.path,
            method: 'get'
          },
          success: (res) => {
            dispatch({
              type: 'INITIAL',
              payload: {
                ...res,
                accessToken,
              },
            })

            settings.onUpdate('themeColorPresets', res.color || primaryPresets[0].name)

            socket.connect()

            socket.emit('register', res.user?.socketUid)

            socket.emit('register', res.slug)
          },
          error: () => {
            forceLogout()
          },
          activeLoading: true,
          authAction: true
        }))
      } else {
        dispatch({
          type: 'INITIAL',
          payload: null,
        })
      }
    } catch (error) {
      dispatch({
        type: 'INITIAL',
        payload: null,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appConfig.actions, outerDispatch])

  useEffect(() => {
    initialize()
  }, [initialize, state.reInit, reInit])

  // LOGIN
  const login = useCallback(async (username, password) => {
    const payload = {
      username,
      password,
    }

    outerDispatch(setLoginFail(false))

    outerDispatch(ajaxCall({
      config: {
        url: appConfig.actions.auth.login.path,
        method: 'post',
        data: payload,
      },
      success: (res) => {
        const { token: accessToken, refreshToken, twoFA } = res

        if (twoFA) {
          return dispatch({
            type: '2FA',
            payload: {
              status: 200,
              twoFA: true,
              username,
              password
            },
          })
        }

        setSession(accessToken)

        localStorage.setItem(window.appConfig.refreshStorageKey, refreshToken)

        dispatch({
          type: 'LOGIN',
          payload: {
            status: 200,
            accessToken,
          },
        })
      },
      error: (err) => {
        if (err.status === 401) {
          outerDispatch(setLoginFail(true))
        }
      },
      activeLoading: true,
      authAction: true
    }))
  }, [appConfig.actions, outerDispatch])

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null)

    socket.disconnect()

    dispatch({
      type: 'LOGOUT',
    })
  }, [])

  // OTP CHECK
  const otpCheck = useCallback(async (data) => {
    outerDispatch(setLoginFail(false))

    outerDispatch(ajaxCall({
      config: {
        url: appConfig.actions.auth.otpCheck.path,
        method: 'post',
        data,
      },
      success: (res) => {
        const { token: accessToken, refreshToken } = res

        setSession(accessToken)

        localStorage.setItem(window.appConfig.refreshStorageKey, refreshToken)

        dispatch({
          type: 'LOGIN',
          payload: {
            status: 200,
            accessToken,
          },
        })
      },
      error: (err) => {
        if (err.status === 401) {
          outerDispatch(setLoginFail(true))
        }
      },
      activeLoading: true,
      authAction: true
    }))
  }, [appConfig.actions, outerDispatch])

  // ----------------------------------------------------------------------

  const checkAuthenticated = (state.data || {}).accessToken ? 'authenticated' : 'unauthenticated'

  const status = state.loading ? 'loading' : checkAuthenticated

  const memoizedValue = useMemo(
    () => ({
      data: state.data,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
      otpCheck
    }),
    [login, logout, otpCheck, state.data, status]
  )

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}
