/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useRef } from 'react'
import { SnackbarProvider as NotistackProvider, SnackbarContent, closeSnackbar } from 'notistack'
// @mui
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
//
import { Card, Tooltip, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { toggleNotificationDrawer } from 'src/reducers/notification'
import { useTheme } from '@emotion/react'
import { Stack } from '@mui/system'
import Iconify from '../iconify'
import { useSettingsContext } from '../settings'
//
import { StyledIcon, StyledNotistack } from './styles'

// ----------------------------------------------------------------------

const SnackbarWithTooltip = forwardRef((props, ref) => {
  const { tooltip } = props

  return (
    <Tooltip title={tooltip.toUpperCase()}>
      <span>
        <StyledNotistack ref={ref} { ...props } />
      </span>
    </Tooltip>
  )
})

const NotificationSnackbar = forwardRef((props, ref) => {
  const dispatch = useDispatch()

  const openDrawer = () => {
    closeSnackbar(props.id)

    setTimeout(() => {
      dispatch(toggleNotificationDrawer())
    }, 500)
  }

  const handleDismiss = useCallback((e) => {
    e.stopPropagation()

    closeSnackbar(props.id)
  }, [props.id])

  const theme = useTheme()

  const style = {
    padding: '4px',
    color: theme.palette.text.primary,
    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
    backgroundColor: `${theme.palette.background.paper} !important`,
    width: '100%',
  }

  return (
    <SnackbarContent ref={ref} style={{ position: 'relative', top: '60px', cursor: 'pointer', ...props.style }} onClick={openDrawer}>
      <Card sx={style} style={{ backgroundColor: '#fddc6c' }}>
        <Stack
          direction='row'
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Stack direction='row' alignItems='center'>
            <StyledIcon color={props.config?.color || 'info'}>
              <Iconify icon={props.config?.icon || 'eva:info-fill'} width={24} />
            </StyledIcon>
            <Typography variant='subtitle2' sx={{ cursor: 'pointer' }}>{props.message}</Typography>
          </Stack>
          <IconButton id='loadingMsgClose' size='small' sx={{ ml: 1 }} onClick={handleDismiss}>
            <Iconify icon='mdi:close' />
          </IconButton>
        </Stack>
      </Card>
    </SnackbarContent>
  )
})

export default function SnackbarProvider({ children }) {
  const settings = useSettingsContext()

  const isRTL = settings.themeDirection === 'rtl'

  const notistackRef = useRef(null)

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={5}
      autoHideDuration={null}
      TransitionComponent={isRTL ? Collapse : undefined}
      variant='success' // Set default variant
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      iconVariant={{
        info: (
          <StyledIcon color='info'>
            <Iconify icon='eva:info-fill' width={24} />
          </StyledIcon>
        ),
        success: (
          <StyledIcon color='success'>
            <Iconify icon='eva:checkmark-circle-2-fill' width={24} />
          </StyledIcon>
        ),
        warning: (
          <StyledIcon color='warning'>
            <Iconify icon='eva:alert-triangle-fill' width={24} />
          </StyledIcon>
        ),
        error: (
          <StyledIcon color='error'>
            <Iconify icon='solar:danger-bold' width={24} />
          </StyledIcon>
        ),
      }}
      Components={{
        default: SnackbarWithTooltip,
        info: SnackbarWithTooltip,
        success: SnackbarWithTooltip,
        warning: SnackbarWithTooltip,
        error: SnackbarWithTooltip,
        notification: NotificationSnackbar,
      }}
      // with close as default
      action={(snackbarId) => (
        <IconButton size='small' onClick={() => closeSnackbar(snackbarId)} sx={{ p: 0.5 }}>
          <Iconify width={16} icon='mingcute:close-line' />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.node,
}
