import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ajaxCall } from './axios'

const initialState = {
  notificationConfig: {},
  isOpen: false,
  notifications: [],
  lastNotifications: [],
  openNotificationDrawer: false,
  pushNotificationDisabled: false,
  socketDisconnected: true,
  serviceDown: false
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    openNotification: (state, action) => {
      state.notificationConfig = action.payload

      state.isOpen = true
    },
    closeNotification: (state) => {
      state.isOpen = false
    },
    addNotifications: (state, action) => {
      const currentIds = state.notifications.map(n => n.id)

      const newNotifications = action.payload.filter(n => !currentIds.includes(n.id))

      state.notifications = [ ...newNotifications, ...state.notifications ]

      state.lastNotifications = newNotifications
    },
    resetNotifications: (state) => {
      state.lastNotifications = []
    },
    toggleNotificationDrawer: (state) => {
      state.openNotificationDrawer = !state.openNotificationDrawer
    },
    markAsRead: (state, action) => {
      state.notifications = state.notifications.map(n => ({
        ...n,
        isUnRead: n.id === action.payload ? false : n.isUnRead
      }))
    },
    updateNotifications: (state, action) => {
      state.notifications = action.payload
    },
    setPushNotificationStatus: (state, action) => {
      state.pushNotificationDisabled = action.payload
    },
    setSocketStatus: (state, action) => {
      state.socketDisconnected = action.payload
    },
    setServiceDownStatus: (state, action) => {
      state.serviceDown = action.payload
    },
  },
})

export const getNotifications = createAsyncThunk('app/notifications', async (options, thunkAPI) => {
  const { appConfig } = thunkAPI.getState().app

  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: appConfig.actions.notification.get.path,
        method: 'get',
      },
      silently: options?.silently,
      success: (res) => {
        thunkAPI.dispatch(setServiceDownStatus(false))

        thunkAPI.dispatch(updateNotifications(res.results))
      },
      error: () => {
        thunkAPI.dispatch(setServiceDownStatus(true))

        setTimeout(() => {
          thunkAPI.dispatch(getNotifications({silently: true}))
        }, 30000)
      },
      activeLoading: false,
    })
  )
})

export const {
  openNotification,
  closeNotification,
  addNotifications,
  resetNotifications,
  toggleNotificationDrawer,
  updateNotifications,
  markAsRead,
  setPushNotificationStatus,
  setSocketStatus,
  setServiceDownStatus
} = notificationSlice.actions

export default notificationSlice.reducer
