import PropTypes from 'prop-types'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @mui
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
//
import { cloneDeep } from 'lodash'
import Iconify from 'src/components/iconify/iconify'
import Label from 'src/components/label'
import { ajaxCall } from 'src/reducers/axios'
import { navMiniConfig } from '../config'
import NavList from './nav-list'

// ----------------------------------------------------------------------

function NavSectionMini({ data, config, sx, ...other }) {
  const { badges } = useSelector((store) => store.sidebar)

  const [dataToRender, setDataToRender] = useState([])

  const dispatch = useDispatch()

  const setIcons = useCallback(
    (item) => {
      if (item.icon) {
        let icon = <Iconify icon={`${item.iconFamily || 'solar'}:${item.icon}`} />

        const hasBadge = !!badges[item.path] || item.children?.find(child => !!badges[child.path])

        if (hasBadge) {
          icon = <Badge color='secondary' variant='dot'>{icon}</Badge>
        }

        Object.assign(item, {
          icon,
        })
      }

      if (item.info) {
        Object.assign(item, {
          info: <Label color='secondary'>{`+${item.info}`}</Label>,
        })
      }

      if (item.children) {
        item.children.forEach(child => {
          setIcons(child)
        })
      }

      if (item.updateBadge) {
        dispatch(
          ajaxCall({
            config: {
              url: item.updateBadge.endpoint,
              method: item.updateBadge.method,
            },
            success: () => {},
            error: () => {},
          })
        )
      }
    },
    [badges, dispatch]
  )

  useEffect(() => {
    setDataToRender(
      cloneDeep(data).map((section) => {
        section.items.forEach((item) => {
          setIcons(item)
        })

        return section
      })
    )
  }, [data, setIcons])

  return (
    <Stack sx={{...sx, pb: 5}} {...other}>
      {dataToRender.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
          config={navMiniConfig(config)}
        />
      ))}
    </Stack>
  )
}

NavSectionMini.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object,
}

export default memo(NavSectionMini)

// ----------------------------------------------------------------------

function Group({ subheader, items, config }) {
  const theme = useTheme()

  return (
    <>
      {subheader ? (
        <Typography
          align='center'
          color={theme.palette.text.disabled}
          sx={{
            fontSize: '0.5rem',
            my: 2,
            mx: 1,
            textTransform: 'uppercase',
            wordWrap: 'break-word'
          }}
        >
          {subheader}
        </Typography>
      ) : (
        <></>
      )}
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  )
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
  subheader: PropTypes.string,
}
