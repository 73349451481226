export const basicPaths = [
  {
    paths: ['table', 'tabella'],
    pageUrl: '/table'
  },
  {
    paths: ['table-ajax'],
    pageUrl: '/table-ajax'
  },
  {
    paths: ['survey', 'survey/:id'],
    pageUrl: '/survey'
  },
  {
    paths: ['todo'],
    pageUrl: '/todo'
  },
  {
    paths: ['form'],
    pageUrl: '/form'
  },
  {
    paths: ['terapia'],
    pageUrl: '/terapia'
  },
  {
    paths: ['agenda'],
    pageUrl: '/agenda'
  },
  {
    paths: ['/'],
    pageUrl: '/healthcare/homepage',
    options: {
      replace: true
    }
  },
  {
    paths: ['anagrafica'],
    pageUrl: '/anagrafica'
  },
  {
    paths: ['anagrafica/more'],
    pageUrl: '/anagrafica2'
  },
  // USER
  {
    paths: ['user'],
    pageUrl: '/auth/user'
  },
  {
    paths: ['user/create'],
    pageUrl: '/auth/user/create'
  },
  {
    paths: ['user/update/:id'],
    pageUrl: '/auth/user/:id/update'
  },
  // USER-MANAGEMENT
  {
    paths: ['user-management'],
    pageUrl: '/auth/user-management'
  },
  {
    paths: ['user-management/create'],
    pageUrl: '/auth/user-management/create'
  },
  {
    paths: ['user-management/update/:id'],
    pageUrl: '/auth/user-management/:id/update'
  },
  // ANAGRAPHIC
  {
    paths: ['anagraphic'],
    pageUrl: '/anagraphic'
  },
  {
    paths: ['anagraphic/create'],
    pageUrl: '/anagraphic/create'
  },
  {
    paths: ['anagraphic/update/:id'],
    pageUrl: '/anagraphic/:id/update'
  },
  // INSTANCE
  {
    paths: ['instance'],
    pageUrl: '/auth/instance'
  },
  {
    paths: ['instance/create'],
    pageUrl: '/auth/instance/create'
  },
  {
    paths: ['instance/update/:id'],
    pageUrl: '/auth/instance/:id/update'
  },
  // PERMISSION
  {
    paths: ['permission'],
    pageUrl: '/auth/permission'
  },
  {
    paths: ['permission/create'],
    pageUrl: '/auth/permission/create'
  },
  {
    paths: ['permission/update/:id'],
    pageUrl: '/auth/permission/:id/update'
  },
  // ROLE
  {
    paths: ['role'],
    pageUrl: '/auth/role'
  },
  {
    paths: ['role/create'],
    pageUrl: '/auth/role/create'
  },
  {
    paths: ['role/update/:id'],
    pageUrl: '/auth/role/:id/update'
  },
  {
    paths: ['role/clone/:id'],
    pageUrl: '/auth/role/:id/clone'
  },
  /**
   * HEALTHCARE
   */
  // DASHBOARD
  {
    paths: ['patient/dashboard/:id'],
    pageUrl: '/healthcare/dashboard/patient/:id'
  },
  // FACILITY
  {
    paths: ['facility'],
    pageUrl: '/healthcare/facility'
  },
  {
    paths: ['facility/create'],
    pageUrl: '/healthcare/facility/create'
  },
  {
    paths: ['facility/update/:id'],
    pageUrl: '/healthcare/facility/:id/update'
  },
  // ROOM
  {
    paths: ['room'],
    pageUrl: '/healthcare/room'
  },
  {
    paths: ['room/create'],
    pageUrl: '/healthcare/room/create'
  },
  {
    paths: ['room/update/:id'],
    pageUrl: '/healthcare/room/:id/update'
  },
  // DIVISION
  {
    paths: ['division'],
    pageUrl: '/healthcare/division'
  },
  {
    paths: ['division/create'],
    pageUrl: '/healthcare/division/create'
  },
  {
    paths: ['division/update/:id'],
    pageUrl: '/healthcare/division/:id/update'
  },
  // PATIENT
  {
    paths: ['patient'],
    pageUrl: '/healthcare/patient'
  },
  {
    paths: ['waiting/patient'],
    pageUrl: '/healthcare/patient/waiting'
  },
  {
    paths: ['pre-admission/patient'],
    pageUrl: '/healthcare/patient/pre-admission'
  },
  {
    paths: ['admission/patient'],
    pageUrl: '/healthcare/patient/admission'
  },
  {
    paths: [
      'patient/create',
      'patient/create/:serviceName',
      ':status/patient/create',
      ':status/patient/create/:serviceName'
    ],
    pageUrl: '/healthcare/:status?/patient/create/:serviceName?'
  },
  {
    paths: [
      'patient/update/:id',
      'patient/update/:id/:serviceName',
      ':status/patient/update/:id/:serviceName?'
    ],
    pageUrl: '/healthcare/:status?/patient/:id/update/:serviceName?'
  },
  // HEALTH-DATA
  {
    paths: ['patient/:id/health-data', 'patient/:id/health-data/:serviceName'],
    pageUrl: '/healthcare/health-data/:id/:serviceName?'
  },
  // DOCTOR
  {
    paths: ['doctor'],
    pageUrl: '/healthcare/doctor'
  },
  {
    paths: ['doctor/create'],
    pageUrl: '/healthcare/doctor/create'
  },
  {
    paths: ['doctor/update/:id'],
    pageUrl: '/healthcare/doctor/:id/update'
  },
  // MEASUREMENT
  {
    paths: ['patient/:id/measurement'],
    pageUrl: '/healthcare/patient/:id/measurement'
  },
  {
    paths: ['patient/:idPatient/measurement/create'],
    pageUrl: '/healthcare/patient/:idPatient/measurement/create'
  },
  {
    paths: ['patient/:idPatient/measurement/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/measurement/:id/update'
  },
  // QUESTION
  {
    paths: ['question'],
    pageUrl: '/healthcare/question'
  },
  {
    paths: ['question/create'],
    pageUrl: '/healthcare/question/create'
  },
  {
    paths: ['question/update/:id'],
    pageUrl: '/healthcare/question/:id/update'
  },
  // MEDICAL-HISTORY
  {
    paths: ['patient/:id/medical-history'],
    pageUrl: '/healthcare/patient/:id/medical-history'
  },
  {
    paths: ['patient/:idPatient/medical-history/create'],
    pageUrl: '/healthcare/patient/:idPatient/medical-history/create'
  },
  {
    paths: ['patient/:idPatient/medical-history/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/medical-history/:id/update'
  },
  // CLINICAL-EXAM
  {
    paths: ['patient/:id/clinical-exam'],
    pageUrl: '/healthcare/patient/:id/clinical-exam'
  },
  {
    paths: ['patient/:idPatient/clinical-exam/create'],
    pageUrl: '/healthcare/patient/:idPatient/clinical-exam/create'
  },
  {
    paths: ['patient/:idPatient/clinical-exam/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/clinical-exam/:id/update'
  },
  // DRUG
  {
    paths: ['drug'],
    pageUrl: '/healthcare/drug'
  },
  {
    paths: ['drug/create'],
    pageUrl: '/healthcare/drug/create'
  },
  {
    paths: ['drug/update/:id'],
    pageUrl: '/healthcare/drug/:id/update'
  },
  // ANTIGEN DRUG
  {
    paths: ['antigen/drug'],
    pageUrl: '/healthcare/antigen/drug'
  },
  {
    paths: ['antigen/drug/create'],
    pageUrl: '/healthcare/antigen/drug/create'
  },
  {
    paths: ['antigen/drug/update/:id'],
    pageUrl: '/healthcare/antigen/drug/:id/update'
  },
  // VACCINE
  {
    paths: ['vaccine'],
    pageUrl: '/healthcare/vaccine'
  },
  {
    paths: ['vaccine/create'],
    pageUrl: '/healthcare/vaccine/create'
  },
  {
    paths: ['vaccine/update/:id'],
    pageUrl: '/healthcare/vaccine/:id/update'
  },
  // TERAPY SCHEDULE
  {
    paths: ['patient/:id/schedule/terapy'],
    pageUrl: '/healthcare/patient/:id/schedule/terapy'
  },
  {
    paths: ['patient/:idPatient/schedule/terapy/create'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/terapy/create'
  },
  {
    paths: ['patient/:idPatient/schedule/terapy/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/terapy/:id/update'
  },
  {
    paths: ['patient/:idPatient/schedule/terapy/clone/:id'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/terapy/:id/clone'
  },
  // AID
  {
    paths: ['aid'],
    pageUrl: '/healthcare/aid'
  },
  {
    paths: ['aid/create'],
    pageUrl: '/healthcare/aid/create'
  },
  {
    paths: ['aid/update/:id'],
    pageUrl: '/healthcare/aid/:id/update'
  },
  // MEASUREMENT SCHEDULE
  {
    paths: ['patient/:id/schedule/measurement'],
    pageUrl: '/healthcare/patient/:id/schedule/measurement'
  },
  {
    paths: ['patient/:idPatient/schedule/measurement/create'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/measurement/create'
  },
  {
    paths: ['patient/:idPatient/schedule/measurement/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/measurement/:id/update'
  },
  // PATIENT-DRUG
  {
    paths: ['patient/:id/patient-drug'],
    pageUrl: '/healthcare/patient/:id/patient-drug'
  },
  {
    paths: ['patient/:idPatient/patient-drug/create'],
    pageUrl: '/healthcare/patient/:idPatient/patient-drug/create'
  },
  {
    paths: ['patient/:idPatient/patient-drug/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/patient-drug/:id/update'
  },
  // PATIENT-VACCINE
  {
    paths: ['patient/:id/patient-vaccine'],
    pageUrl: '/healthcare/patient/:id/patient-vaccine'
  },
  {
    paths: ['patient/:idPatient/patient-vaccine/create'],
    pageUrl: '/healthcare/patient/:idPatient/patient-vaccine/create'
  },
  {
    paths: ['patient/:idPatient/patient-vaccine/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/patient-vaccine/:id/update'
  },
  // ACTIVITY
  {
    paths: ['activity'],
    pageUrl: '/healthcare/activity'
  },
  {
    paths: ['patient/:id/activity'],
    pageUrl: '/healthcare/patient/:id/activity'
  },
  {
    paths: ['patient/:idPatient/activity/:idActivity/:activityName/create'],
    pageUrl: '/healthcare/patient/:idPatient/activity/:idActivity/:activityName/create'
  },
  // QUESTIONNAIRE
  {
    paths: ['patient/:idPatient/questionnaire/:type/create'],
    pageUrl: '/healthcare/patient/:idPatient/questionnaire/:type/create'
  },
  {
    paths: ['patient/:idPatient/questionnaire/update/:idQuestionnaire'],
    pageUrl: '/healthcare/patient/:idPatient/questionnaire/:idQuestionnaire/update'
  },
  {
    paths: ['patient/:idPatient/questionnaire/:type'],
    pageUrl: '/healthcare/patient/:idPatient/questionnaire/:type'
  },
  // FILE
  {
    paths: ['patient/:id/file'],
    pageUrl: '/healthcare/patient/:id/file'
  },
  {
    paths: ['patient/:idPatient/file/create'],
    pageUrl: '/healthcare/patient/:idPatient/file/create'
  },
  {
    paths: ['patient/:idPatient/file/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/file/:id/update'
  },
  // MEMO
  {
    paths: ['memo'],
    pageUrl: '/memo'
  },
  {
    paths: ['memo/create'],
    pageUrl: '/memo/create'
  },
  {
    paths: ['memo/update/:id'],
    pageUrl: '/memo/:id/update'
  },
  // MEMO - USER
  {
    paths: ['user-memo'],
    pageUrl: '/memo/memo-user-status'
  },
  // CALENDAR
  {
    paths: ['calendar'],
    pageUrl: '/calendar'
  },
  // CLOCKING
  {
    paths: ['patient/:id/clocking'],
    pageUrl: '/healthcare/patient/:id/clocking'
  },
  {
    paths: ['patient/:idPatient/clocking/create'],
    pageUrl: '/healthcare/patient/:idPatient/clocking/create'
  },
  {
    paths: ['patient/:idPatient/clocking/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/clocking/:id/update'
  },
  // WAREHOUSE
  {
    paths: ['warehouse/item'],
    pageUrl: '/warehouse/item'
  },
  {
    paths: ['warehouse/item/:itemType/create'],
    pageUrl: '/warehouse/item/:itemType/create'
  },
  {
    paths: ['warehouse/item/update/:id/:itemType'],
    pageUrl: '/warehouse/item/:id/:itemType/update'
  },
  // ACCESSORY
  {
    paths: ['accessory'],
    pageUrl: '/healthcare/accessory'
  },
  {
    paths: ['accessory/create'],
    pageUrl: '/healthcare/accessory/create'
  },
  {
    paths: ['accessory/update/:id'],
    pageUrl: '/healthcare/accessory/:id/update'
  },
  // QUESTIONNAIRE-CONFIG
  {
    paths: ['patient/:idPatient/questionnaire-config'],
    pageUrl: '/healthcare/patient/:idPatient/questionnaire-config'
  },
  // ALERTS
  {
    paths: ['alert'],
    pageUrl: '/alerts/alert'
  },
  {
    paths: ['alerts/alert/create/:type'],
    pageUrl: '/alerts/alert/create/:type'
  },
  // BLOODTEST SCHEDULE
  {
    paths: ['patient/:id/schedule/bloodtest'],
    pageUrl: '/healthcare/patient/:id/schedule/bloodtest'
  },
  {
    paths: ['patient/:idPatient/schedule/bloodtest/:type/create'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/bloodtest/:type/create'
  },
  {
    paths: ['patient/:idPatient/schedule/bloodtest/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/bloodtest/:id/update'
  },
  // BLOODTEST
  {
    paths: ['patient/:id/bloodtest'],
    pageUrl: '/healthcare/patient/:id/bloodtest'
  },
  {
    paths: ['patient/:idPatient/bloodtest/create'],
    pageUrl: '/healthcare/patient/:idPatient/bloodtest/create'
  },
  {
    paths: ['patient/:idPatient/bloodtest/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/bloodtest/:id/update'
  },
  // STRUMENTAL-EXAM SCHEDULE
  {
    paths: ['patient/:id/schedule/strumental-exam'],
    pageUrl: '/healthcare/patient/:id/schedule/strumental-exam'
  },
  {
    paths: ['patient/:idPatient/schedule/strumental-exam/create'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/strumental-exam/create'
  },
  {
    paths: ['patient/:idPatient/schedule/strumental-exam/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/strumental-exam/:id/update'
  },
  // STRUMENTAL-EXAM
  {
    paths: ['patient/:id/strumental-exam'],
    pageUrl: '/healthcare/patient/:id/strumental-exam'
  },
  {
    paths: ['patient/:idPatient/strumental-exam/create'],
    pageUrl: '/healthcare/patient/:idPatient/strumental-exam/create'
  },
  {
    paths: ['patient/:idPatient/strumental-exam/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/strumental-exam/:id/update'
  },
  // CATHETER SCHEDULE
  {
    paths: ['patient/:id/schedule/catheter'],
    pageUrl: '/healthcare/patient/:id/schedule/catheter'
  },
  {
    paths: ['patient/:idPatient/schedule/catheter/create'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/catheter/create'
  },
  {
    paths: ['patient/:idPatient/schedule/catheter/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/schedule/catheter/:id/update'
  },
  // CATHETER
  {
    paths: ['patient/:id/catheter'],
    pageUrl: '/healthcare/patient/:id/catheter'
  },
  {
    paths: ['patient/:idPatient/catheter/create'],
    pageUrl: '/healthcare/patient/:idPatient/catheter/create'
  },
  {
    paths: ['patient/:idPatient/catheter/update/:id'],
    pageUrl: '/healthcare/patient/:idPatient/catheter/:id/update'
  },
  // HOME-NOTIFICATION
  {
    paths: ['home-notification'],
    pageUrl: '/pusher/home-notification'
  },
  {
    paths: ['home-notification/create'],
    pageUrl: '/pusher/home-notification/create'
  },
  {
    paths: ['home-notification/update/:id'],
    pageUrl: '/pusher/home-notification/:id/update'
  },
  // WIZARD
  {
    paths: ['wizard'],
    pageUrl: '/healthcare/wizard'
  },
  {
    paths: ['wizard/create/:idGroup'],
    pageUrl: '/healthcare/wizard/create/:idGroup'
  },
  // WIZARD-STEPS
  {
    paths: ['/patient/:id/wizard/steps/group/:idGroup'],
    pageUrl: '/healthcare/patient/:id/wizard/steps/group/:idGroup'
  },
  // USER LOG
  {
    paths: ['logs'],
    pageUrl: '/logs'
  },
  {
    paths: ['logs/page/:id'],
    pageUrl: '/logs/:id'
  }
]
