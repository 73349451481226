import PropTypes from 'prop-types'
// @mui
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import { Avatar, ListItemAvatar } from '@mui/material'
import Iconify from 'src/components/iconify'
import { useNavigate } from 'react-router'
import moment from 'moment'

// ----------------------------------------------------------------------

export default function NotificationItem({ notification, markAsRead, closeDrawer }) {
  const navigate = useNavigate()

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(notification.title)}
      secondary={
        <Stack
          direction='row'
          alignItems='center'
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%'
              }}
            />
          }
        >
          {moment(notification.time, 'DD/MM/YYYY HH:mm').fromNow()}
        </Stack>
      }
    />
  )

  const renderIcon = notification.icon && (
    <ListItemAvatar>
      <Avatar sx={notification.icon.color && { bgcolor: notification.icon.color }}>
        <Iconify icon={`${notification.icon.iconFamily || 'solar'}:${notification.icon.name}`} />
      </Avatar>
    </ListItemAvatar>
  )

  const renderUnReadBadge = notification.isUnRead && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute'
      }}
    />
  )

  const handleClick = () => {
    markAsRead(notification)

    closeDrawer()

    if (notification.path) {
      navigate(window.appConfig.appPrefix + notification.path)
    }
  }

  return (
    <ListItemButton
      disableRipple
      onClick={handleClick}
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`
      }}
    >
      {renderUnReadBadge}

      {renderIcon}

      <Stack sx={{ flexGrow: 1 }}>{renderText}</Stack>
    </ListItemButton>
  )
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  markAsRead: PropTypes.func,
  closeDrawer: PropTypes.func
}

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  )
}
