// @mui
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useDispatch, useSelector } from 'react-redux'
import { closeConfirm, handleConfirm } from 'src/reducers/confirm'

// ----------------------------------------------------------------------

export default function Confirm() {
  const confirmConfig = useSelector((state) => state.confirm.confirmConfig)

  const isOpen = useSelector((state) => state.confirm.isOpen)

  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(closeConfirm())
  }

  const onConfirm = () => {
    dispatch(handleConfirm())
  }

  return (
    <Dialog fullWidth maxWidth='xs' open={isOpen}>
      <DialogTitle sx={{ pb: 2 }}>{confirmConfig.title}</DialogTitle>

      {confirmConfig.message && (
        <DialogContent sx={{ typography: 'body2' }}> {confirmConfig.message} </DialogContent>
      )}

      <DialogActions>
        <Button variant='outlined' color='inherit' onClick={onClose}>
          {confirmConfig.ko}
        </Button>

        <Button variant='contained' color={confirmConfig.color || 'error'} onClick={onConfirm}>
          {confirmConfig.ok}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
