import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ajaxCall } from './axios'

const initialState = {
  printsStack: [],
}

export const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    updateStack: (state, action) => {
      state.printsStack = action.payload
    },
    updatePrint: (state, action) => {
      const print = state.printsStack.find(p => p.id === action.payload.id)

      if (print) {
        if (print.status !== 'success' && print.status !== 'error' && print.timestamp < action.payload.timestamp) {
          state.printsStack = state.printsStack.map(elem => elem.id === action.payload.id ? action.payload : elem)
        }
      } else {
        state.printsStack = [ action.payload, ...state.printsStack]
      }
    },
    removeFromStack: (state, action) => {
      state.printsStack = state.printsStack.filter(p => p.id !== action.payload)
    },
  }
})

export const {
  updateStack,
  updatePrint,
  removeFromStack,
} = printSlice.actions

export const getPrintsStack = createAsyncThunk('app/print', async (_, thunkAPI) => {
  const { appConfig } = thunkAPI.getState().app

  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: appConfig.actions.print.get.path,
        method: 'get',
      },
      success: (res) => {
        thunkAPI.dispatch(updateStack(res.results))
      },
      error: () => {},
      activeLoading: false,
    })
  )
})

export const cancelPrint = createAsyncThunk('app/print', async (data, thunkAPI) => {
  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: data.url,
        method: data.method,
      },
      success: () => {
        thunkAPI.dispatch(removeFromStack(data.id))
      },
      error: () => {},
      activeLoading: false,
    })
  )
})

export const printPost = createAsyncThunk('app/print', async (data, thunkAPI) => {
  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: data.url,
        method: 'post',
        data: data.params
      },
      success: () => {},
      error: () => {},
      activeLoading: false,
    })
  )
})

export default printSlice.reducer
