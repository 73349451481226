import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep, set } from 'lodash'
import { ajaxCall } from './axios'
import { updateErrors } from './form'

const initialState = {
  appConfig: window.appConfig || {},
  titleBlock: {
    title: null,
    breadcrumbs: [],
  },
  pageType: null,
  alert: null,
  pageContent: {
    fields: [],
  },
  sidebar: [],
  table: [],
  pagination: null,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateTitleBlock: (state, action) => {
      state.titleBlock = action.payload
    },
    updateSidebar: (state, action) => {
      state.sidebar = action.payload
    },
    updatePage: (state, action) => {
      state.pageContent = action.payload
    },
    updatePageType: (state, action) => {
      state.pageType = action.payload
    },
    updateTable: (state, action) => {
      state.table = action.payload
    },
    updatePagination: (state, action) => {
      state.pagination = action.payload
    },
    updateFields: (state, action) => {
      const name = action.payload.name.split('.')

      if (!state.pageContent) {
        state.pageContent = {}
      }

      if (!state.pageContent.fields) {
        state.pageContent.fields = []
      }

      state.pageContent.fields = state.pageContent.fields.map((field) => {
        if (field.name === name[0]) {
          if (name.length > 1) {
            return field
          }

          return {
            ...field,
            ...action.payload.toUpdate,
          }
        }

        return field
      })
    },
    updateBlocks: (state, action) => {
      const newBlocks = cloneDeep(state.pageContent.blocks)

      Object.entries(action.payload.toUpdate).forEach(([key, val]) => {
        set(newBlocks, [...action.payload.path, key], val)
      })

      state.pageContent.blocks = newBlocks
    },
    updateAlert: (state, action) => {
      state.alert = action.payload
    },
  },
})

export const {
  updateTitleBlock,
  updateSidebar,
  updatePage,
  updateTable,
  updatePagination,
  updatePageType,
  updateFields,
  updateBlocks,
  updateAlert,
} = appSlice.actions

export const getPage = createAsyncThunk('app/page', async (path, thunkAPI) => {
  thunkAPI.dispatch(updatePageType())

  thunkAPI.dispatch(updatePage({}))

  thunkAPI.dispatch(updateErrors({}))

  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: path,
        method: 'get',
      },
      success: (res) => {
        thunkAPI.dispatch(updateTitleBlock(res.titleBlock))

        thunkAPI.dispatch(updatePage(res.pageContent))

        thunkAPI.dispatch(updatePageType(res.pageType))

        thunkAPI.dispatch(updateAlert(res.alert))
      },
      error: () => {},
      activeLoading: true,
    })
  )
})

export const loadTable = createAsyncThunk('app/table', async (path, thunkAPI) => {
  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: path,
        method: 'get',
      },
      success: (res) => {
        thunkAPI.dispatch(updateTable(res.results))

        thunkAPI.dispatch(updatePagination(res.pagination))
      },
      error: () => {},
      activeLoading: false,
    })
  )
})

export const loadTableAjax = createAsyncThunk('app/table-ajax', async (obj, thunkAPI) => {
  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: obj.url,
        method: 'get',
        params: obj.params
      },
      success: (res) => {
        thunkAPI.dispatch(updateTable(res.results))

        thunkAPI.dispatch(updatePagination(res.pagination))
      },
      error: () => {},
      activeLoading: false,
    })
  )
})

export const getSidebar = createAsyncThunk('app/sidebar', async (_, thunkAPI) => {
  const { appConfig } = thunkAPI.getState().app

  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: appConfig.actions.sidebar.path,
        method: 'get',
      },
      success: (res) => {
        thunkAPI.dispatch(updateSidebar(res))
      },
      error: () => {},
      activeLoading: false,
    })
  )
})

export default appSlice.reducer
