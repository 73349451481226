import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axios from 'src/utils/axios'
import { uniqueId , cloneDeep, isArray } from 'lodash'
import { forceLogout, setSession } from 'src/auth/context/jwt/utils'
import { showMessage } from './message'

const initialState = {
  callsTail: [],
  openLoading: true,
  reInit: false,
  forbidden: false
}

const stopLoading = (activeLoading, thunkAPI) => {
  const { callsTail } = thunkAPI.getState().axios

  if (activeLoading && !callsTail.length) {
    return setTimeout(() => {
      thunkAPI.dispatch(updateLoading(false))
    }, 10)
  }

  if (callsTail.length) {
    return setTimeout(() => {
      stopLoading(activeLoading, thunkAPI)
    }, 1000)
  }

  return ''
}

const startLoading = (thunkAPI) => {
  thunkAPI.dispatch(updateLoading(true))
}

export const axiosSlice = createSlice({
  name: 'axios',
  initialState,
  reducers: {
    pushCallsTail: (state, action) => {
      const tmpTail = [...state.callsTail]

      tmpTail.push(action.payload)

      state.callsTail = tmpTail
    },
    removeCallsTail: (state, action) => {
      const tmpTail = [...state.callsTail]

      tmpTail.splice(state.callsTail.indexOf(action.payload), 1)

      state.callsTail = tmpTail
    },
    updateLoading: (state, action) => {
      state.openLoading = action.payload
    },
    forceReInit: (state) => {
      state.reInit = !state.reInit
    },
    setForbidden: (state, action) => {
      state.forbidden = action.payload
    },
  },
})

export const ajaxCall = createAsyncThunk('axios/ajaxCall', async (obj, thunkAPI) => {
  const callId = uniqueId('call_')

  const initialObj = cloneDeep(obj)

  thunkAPI.dispatch(pushCallsTail(callId))

  obj.config.headers = {
    ...axios.defaults.headers.common,
    Accept: 'application/json',
  }

  obj.config.timeout = obj.config.timeout || 300000

  axios.defaults.withCredentials = true

  const { appConfig } = thunkAPI.getState().app

  const { activeLoading, fullResponse } = obj

  if (activeLoading) {
    startLoading(thunkAPI)
  }

  obj.config.url = appConfig.apiUrl + obj.config.url

  if (obj.config.url.includes('/:db/')) {
    obj.config.url = obj.config.url.replace('/:db/', `/${appConfig.selectedDb.value}/`)
  }

  (obj.config.url.match(/\/:[a-zA-Z0-9]+/is) || []).forEach((item) => {
    const param = item.replace('/:', '')

    obj.config.url = obj.config.url.replace(item, `/${obj.config.params[param]}`)
  })

  obj.config.params = {
    ...obj.config.params,
    v: new Date().getTime(),
  }

  setTimeout(() => {
    axios(obj.config)
      .then((res) => {
        if (
          res.headers.authtoken &&
          !obj.silently &&
          !obj.preventToken &&
          localStorage.getItem('token')
        ) {
          localStorage.setItem('token', res.headers.authtoken)
        }

        thunkAPI.dispatch(removeCallsTail(callId))

        stopLoading(activeLoading, thunkAPI)

        const resContent = res.data

        if (resContent.systemMessages) {
          Object.entries(resContent.systemMessages).forEach(([key, value]) => {
            if (!obj.silently) {
              thunkAPI.dispatch(
                showMessage({
                  ...value,
                  autoHide: false,
                })
              )
            }
          })
        }

        if (res.status !== 200 && resContent.message) {
          thunkAPI.dispatch(
            showMessage({
              message: resContent.message,
              type: 'error',
              autoHide: true,
            })
          )
        }

        if (resContent.payload && !isArray(resContent.payload)) {
          resContent.payload.message = resContent.message

          resContent.payload.status = res.status

          if (resContent.goTo) {
            resContent.payload.goTo = resContent.goTo
          }
        } else if (!isArray(resContent)) {
          resContent.status = res.status
        }

        if (resContent.forceLogout) {
          return forceLogout()
        }

        if (resContent.forceRefresh) {
          thunkAPI.dispatch(forceReInit())
        }

        if (fullResponse) {
          return obj.success(res)
        }

        return obj.success(resContent.payload || resContent)
      })
      .catch((error = {}) => {
        const { data: errorData = {}, status } = error

        thunkAPI.dispatch(removeCallsTail(callId))

        stopLoading(activeLoading, thunkAPI)

        if (typeof errorData !== 'object') {
          return thunkAPI.dispatch(
            showMessage({
              message: 'Errore sconosciuto',
              tooltip: '?',
              type: 'error',
              autoHide: true,
            })
          )
        }

        errorData.status = status

        if (status === 401 && !obj.refreshingToken && !obj.authAction) {
          const refresh = window.localStorage.getItem(window.appConfig.refreshStorageKey)

          return axios.post(appConfig.actions.auth.refreshToken.path, {}, {
              headers: {
                Authorization: `Bearer ${refresh}`
              }
            })
            .then((response) => {
              const newAuth = response.data?.payload

              setSession(newAuth.token)

              if (newAuth.refreshToken) {
                localStorage.setItem(window.appConfig.refreshStorageKey, newAuth.refreshToken)
              }

              initialObj.refreshingToken = true

              thunkAPI.dispatch(ajaxCall(initialObj))
            })
            .catch((e) => {
              forceLogout()
            })
        }

        if (status === 403 && obj.config.url.includes('/page')) {
          return thunkAPI.dispatch(setForbidden(true))
        }

        if (!obj.silently) {
          thunkAPI.dispatch(
            showMessage({
              message: errorData.message || 'Errore',
              tooltip: errorData.errorCode,
              type: 'error',
              autoHide: true,
            })
          )
        }

        if (typeof obj.error === 'function') {
          return obj.error(errorData)
        }

        return ''
      })
  }, 10)
})

export const { pushCallsTail, removeCallsTail, updateLoading, forceReInit, setForbidden } =
  axiosSlice.actions

export default axiosSlice.reducer
