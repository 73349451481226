const {
  REACT_APP_NODE_ENV,
  REACT_APP_IS_RSA,
  REACT_APP_API_URL,
  REACT_APP_RSA_FCM_APIKEY,
  REACT_APP_RSA_FCM_AUTHDOMAIN,
  REACT_APP_RSA_FCM_PROJECTID,
  REACT_APP_RSA_FCM_STORAGEBUCKET,
  REACT_APP_RSA_FCM_MESSAGINGSENDERID,
  REACT_APP_RSA_FCM_APPID,
  REACT_APP_RSA_VAPID_PUB_KEY
} = process.env

window.appConfig = {
  tokenStorageKey: 'accessToken',
  refreshStorageKey: 'refreshToken',
  timeouts: {},
  appPrefix: '',
  appEnv: REACT_APP_NODE_ENV,
  apiUrl: REACT_APP_API_URL,
  dateFormat: 'DD/MM/YYYY',
  push_notification: {
    firebase: {
      apiKey: REACT_APP_RSA_FCM_APIKEY,
      authDomain: REACT_APP_RSA_FCM_AUTHDOMAIN,
      projectId: REACT_APP_RSA_FCM_PROJECTID,
      storageBucket: REACT_APP_RSA_FCM_STORAGEBUCKET,
      messagingSenderId: REACT_APP_RSA_FCM_MESSAGINGSENDERID,
      appId: REACT_APP_RSA_FCM_APPID
    },
    vapidPubKey: REACT_APP_RSA_VAPID_PUB_KEY
  },
  fileErrors: {
    'file-too-large': 'Il file supera le dimensioni consentite',
    'file-invalid-type': 'Il tipo di file non è supportato',
    'file-too-small': 'Il file è inferiiore alle dimensioni consentite',
    'too-many-files': 'Sono stati caricati troppi file',
  },
  actions: {
    auth: {
      login: {
        path: '/auth/login',
      },
      loginfail: {
        path: '/auth/loginfail',
      },
      otpCheck: {
        path: '/auth/otp/check',
      },
      appConfig: {
        path: '/auth/app-config',
      },
      refreshToken: {
        path: '/auth/refresh-token'
      },
      recovery: {
        path: '/auth/recovery'
      },
      changePassword: {
        path: '/auth/change-password'
      },
      otpRecovery: {
        path: '/auth/otp/recovery'
      }
    },
    sidebar: {
      path: '/auth/sidebar'
    },
    notification: {
      subscribe: {
        path: '/pusher/notification/subscribe'
      },
      get: {
        path: '/pusher/notification/data'
      }
    },
    print: {
      get: {
        path: '/filemanager/print/queue'
      },
    },
    survey: {
      save: {
        path: '/survey/save'
      }
    }
  },
}
