import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ajaxCall } from './axios'
import { showMessage } from './message'

const initialState = {
  form: null,
  goTo: null,
  errors: {},
  loginFail: false,
  closeModal: false
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = null
    },
    formSaved: (state, action) => {
      state.goTo = action.payload.goTo || action.payload.data.goTo
    },
    resetGoTo: (state) => {
      state.goTo = null
    },
    updateErrors: (state, action) => {
      state.errors = action.payload
    },
    setLoginFail: (state, action) => {
      state.loginFail = action.payload
    },
    setCloseModal: (state, action) => {
      state.closeModal = action.payload
    },
    updateFields: (state, action) => {
      if (!state.form) {
        return
      }

      const name = action.payload.name.split('.')

      state.form.pageContent[action.payload.fieldsKey] = state.form.pageContent[
        action.payload.fieldsKey
      ].map((field) => {
        if (field.name === name[0]) {
          if (name.length > 1) {
            return field
          }

          return {
            ...field,
            ...action.payload.toUpdate,
          }
        }

        return field
      })
    },
  },
})

export const {
  updateForm,
  resetForm,
  formSaved,
  resetGoTo,
  updateErrors,
  setLoginFail,
  setCloseModal,
  updateFields,
} = formSlice.actions

export const saveForm = createAsyncThunk('form/save', async (data, thunkAPI) => {
  const { formValues, asNew, params, id, uid, url, method } = data

  const trimmedFormValues = Object.entries(formValues).reduce((acc, [key, value]) => {
    acc[key] = typeof value === 'string' ? value.trim() : value
    
    return acc
  }, {})

  const formContent = {
    fields: trimmedFormValues,
    id,
    uid,
  }

  if (asNew) {
    formContent.id = null

    formContent.uid = null
  }

  if (params.temporarySave) {
    params.isDraft = 1
  }

  thunkAPI.dispatch(updateErrors({}))

  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url,
        method: method || 'post',
        data: formContent,
        params,
      },
      success: (res) => {
        if (res.status === 200) {
          thunkAPI.dispatch(formSaved({ data: res, ...params }))

          thunkAPI.dispatch(setCloseModal(true))
        }

        if (!params.temporarySave && res.status === 200 && res.message) {
          setTimeout(() => {
            thunkAPI.dispatch(showMessage({
              message: res.message,
              type: 'success',
              autoHide: true,
            }))
          }, 200)
        }
      },
      error: (res) => {
        if (res.status === 400) {
          thunkAPI.dispatch(updateErrors(res.payload?.fields))
        }
      },
      activeLoading: true,
    })
  )
})

export const getForm = createAsyncThunk('form/getForm', async (data, thunkAPI) => {
  thunkAPI.dispatch(
    ajaxCall({
      config: {
        url: data.url,
        method: 'get',
        params: data.params,
      },
      success: (res) => {
        thunkAPI.dispatch(updateForm(res))
      },
    })
  )
})

export default formSlice.reducer
