import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment/locale/it'
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
//
import useLocales from './use-locales'

// ----------------------------------------------------------------------

export default function LocalizationProvider({ children }) {
  const { currentLang } = useLocales()

  moment.locale(currentLang.value)

  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLang.adapterLocale}>
      {children}
    </MuiLocalizationProvider>
  )
}

LocalizationProvider.propTypes = {
  children: PropTypes.node,
}
