// routes
import { paths } from 'src/routes/paths'
import { socket } from 'src/socket'
// utils
import axios from 'src/utils/axios'

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }

  const decoded = jwtDecode(accessToken)

  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer

  const currentTime = Date.now()

  const timeLeft = exp * 1000 - currentTime

  clearTimeout(expiredTimer)

  expiredTimer = setTimeout(() => {
    localStorage.removeItem(window.appConfig.tokenStorageKey)

    window.location.href = paths.login
  }, timeLeft)
}

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem(window.appConfig.tokenStorageKey, accessToken)

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem(window.appConfig.tokenStorageKey)

    delete axios.defaults.headers.common.Authorization
  }
}

// ----------------------------------------------------------------------

export const forceLogout = () => {
  setSession(null)

  const searchParams = new URLSearchParams({
    returnTo: window.location.pathname,
  }).toString()

  const loginPath = paths.login

  const href = `${loginPath}?${searchParams}`

  window.location.href = href

  socket.disconnect()
}
