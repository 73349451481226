import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  badges: {},
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    updateBadge: (state, action) => {
      Object.entries(action.payload || {}).forEach(([id, count]) => {
        if (count !== state.badges[id]) {
          state.badges[id] = count
        }
      })
    },
  },
})

export const { updateBadge } = sidebarSlice.actions

export default sidebarSlice.reducer
